import { createGlobalStyle } from 'styled-components';
import { colors } from '@/styles/colors';

export const GlobalStyles = createGlobalStyle`
  #__next {
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  html,
  body,
  #app,
  #app>div {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: ${colors.secondary};
    color: ${colors.textPrimary};
  }
`;
