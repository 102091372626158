import { colors } from '@/styles/colors';
import { Fonts, NotoSansFont, OpenSansFont } from '@/styles/fonts';
import { useState, ReactNode } from 'react';

type Props = {
    onClick: () => void;
    style?:
        | 'sm'
        | 'nav-logo'
        | 'lg'
        | 'lg-secondary'
        | 'lg-floating'
        | 'lg-floating-secondary'
        | 'sm-nav'
        | 'sm-bottom-nav'
        | 'signin'
        | 'provider'
        | 'dropdown'
        | 'contact';
    font?: Fonts;
    className?: string;
    children?: ReactNode;
    isDisabled?: boolean;
};

type HNButtonStyle = {
    fontSize: string;
    width?: string;
    height: string;
    fontStyle: string;
    letterSpacing?: string;
    textColor?: string;
    hoverTextColor?: string;
    borderColor?: string;
    hoverBorderColor?: string;
    backgroundColor?: string;
    hoverBackgroundColor?: string;
};

const styleDictionaryFromStyle = (style: string): HNButtonStyle => {
    let fontSize: string;
    let width: string | undefined;
    let height: string;
    let fontStyle: string;
    let letterSpacing: string | undefined;
    let borderColor: string | undefined;
    let backgroundColor = colors.primary;
    let textColor = colors.offWhite;
    let hoverBackgroundColor = colors.primary;
    let hoverBorderColor: string | undefined;
    let hoverTextColor = colors.textSecondary;

    switch (style) {
        case 'lg':
        default:
            fontSize = '18px';
            height = '50px';
            fontStyle = 'bold';
            letterSpacing = '0.5px';
            break;
        case 'lg-secondary':
            fontSize = '20px';
            height = '50px';
            fontStyle = 'bold';
            backgroundColor = colors.offWhite;
            borderColor = colors.textPrimary;
            textColor = 'rgba(0, 0, 0, 0.8)';
            hoverTextColor = colors.textPrimary;
            hoverBackgroundColor = colors.offWhite;
            break;
        case 'lg-floating':
            fontSize = '20px';
            height = '52px';
            fontStyle = 'bold';
            textColor = 'rgba(0, 0, 0, 0.7)';
            hoverTextColor = 'black';
            break;
        case 'lg-floating-secondary':
            fontSize = '20px';
            height = '52px';
            fontStyle = 'bold';
            backgroundColor = 'lightgray';
            hoverTextColor = colors.textPrimary;
            textColor = colors.textPrimary;
            break;
        case 'nav-logo':
            fontSize = '30px';
            height = '53px';
            fontStyle = 'bold';
            letterSpacing = '3px';
            break;
        case 'sm-nav':
            fontSize = '16px';
            height = '53px';
            fontStyle = 'light';
            letterSpacing = '1.5px';
            break;
        case 'sm-bottom-nav':
            fontSize = '16px';
            height = '35px';
            fontStyle = 'light';
            letterSpacing = '1px';
            hoverBackgroundColor = backgroundColor;
            break;
        case 'signin':
            fontSize = '17px';
            width = '153px';
            height = '50px';
            fontStyle = 'bold';
            break;
        case 'provider':
            fontSize = '17px';
            width = '315px';
            height = '50px';
            fontStyle = 'bold';
            break;
        case 'dropdown':
            fontSize = '17px';
            width = '120px';
            height = '30px';
            fontStyle = 'light';
            break;
        case 'contact':
            fontSize = '20px';
            width = '350px';
            height = '50px';
            fontStyle = 'bold';
            backgroundColor = 'rgba(0, 0, 0, 0.97)';
            break;
    }

    return {
        fontSize,
        width,
        height,
        fontStyle,
        letterSpacing,
        textColor,
        hoverTextColor,
        borderColor,
        hoverBorderColor,
        backgroundColor,
        hoverBackgroundColor
    };
};

const HNButton = ({ children, onClick, style = 'sm', font = Fonts.NotoSans, className, isDisabled }: Props) => {
    const cssStyle = styleDictionaryFromStyle(style);
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    const fontObject = font === Fonts.NotoSans ? NotoSansFont : OpenSansFont;

    let backgroundColor = isMouseOver ? cssStyle.hoverBackgroundColor : cssStyle.backgroundColor;
    backgroundColor = isDisabled ? 'gray' : backgroundColor;
    return (
        <button
            dir="rtl"
            onMouseOver={() => !isDisabled && setIsMouseOver(true)}
            onMouseOut={() => !isDisabled && setIsMouseOver(false)}
            className={`${fontObject.className} ${className}`}
            onClick={onClick}
            disabled={isDisabled}
            style={{
                ...cssStyle,
                backgroundColor,
                color: isMouseOver ? cssStyle.hoverTextColor : cssStyle.textColor,
                borderRadius: '6px',
                border: cssStyle.borderColor ? `2px solid ${isMouseOver ? cssStyle.hoverBorderColor : cssStyle.borderColor}` : '',
                cursor: !isDisabled ? 'pointer' : undefined,
                textAlign: 'center',
                whiteSpace: 'nowrap'
            }}
        >
            {children}
        </button>
    );
};

export default HNButton;
