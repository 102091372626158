import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { ApolloProvider } from '@apollo/client';
import useApollo from '@/apollo-client';
import NavigationBar from '@/components/NavigationBar';
import { GlobalStyles } from '@/styles/globals';
import Head from 'next/head';
import { styled } from 'styled-components';
import { NextUIProvider } from '@nextui-org/system';
import '@/styles/tailwind.css';
import BottomBar from '@/components/BottomBar';
import config from '@/lib/config';
import { useRouter } from 'next/router';
import { AuthPages } from '@/lib/types/types';

const MyApp: React.FC<AppProps> = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
    const router = useRouter();
    const apolloClient = useApollo();

    useEffect(() => {
        if (![AuthPages.signIn, '/redirect'].includes(router.asPath)) {
            localStorage.setItem('lastVisitedPath', router.asPath);
        }
    }, [router.asPath]);

    return (
        <>
            <Head>
                <title>הזמנינא</title>
                <link rel="icon" href="/smiling-letter.png" />
            </Head>
            <GlobalStyles />
            <SessionProvider session={session} refetchInterval={30 * 60} refetchOnWindowFocus={true}>
                <ApolloProvider client={apolloClient}>
                    <NextUIProvider>
                        <Container className="dark">
                            <InnerContainer>
                                <NavigationBar />
                                <ComponentContainer>
                                    <Component {...pageProps} />
                                </ComponentContainer>
                                <BottomBar />
                            </InnerContainer>
                        </Container>
                    </NextUIProvider>
                </ApolloProvider>
            </SessionProvider>
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const InnerContainer = styled.div`
    width: min(100vw, 1800px);
    min-height: 100vh;
`;

const ComponentContainer = styled.div`
    height: calc(100% - 200px - ${config.ui.navbarHeight});

    @media screen and (max-width: 900px) {
        height: calc(100% - 300px - ${config.ui.navbarHeight});
    }
`;

export default MyApp;
