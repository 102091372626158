import { Fonts } from '@/styles/fonts';
import { OpenSansFont, NotoSansFont } from '@/styles/fonts';
import { ReactNode } from 'react';

type Props = {
    children: ReactNode;
    font?: Fonts;
    className?: string;
};

const HNText = ({ children, font = Fonts.OpenSans, className }: Props) => {
    const fontClassName = font === Fonts.OpenSans ? OpenSansFont.className : NotoSansFont.className;

    return (
        <div dir="rtl" className={`${fontClassName} ${className}`}>
            {children}
        </div>
    );
};

export default HNText;
