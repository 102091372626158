/**
 * API context is used to store user session details.
 */
export type ApiContext = {
    user?: {
        _id: string;
        email: string;
        fingerprint?: string;
    };
};

export enum MongoCollectionsEnum {
    USERS = 'users',
    PROJECTS = 'projects',
    CONTACT_US_REQUEST = 'contact_us_request'
}

export enum AuthPages {
    signIn = '/auth/signin',
    signOut = '/auth/signout',
    error = '/auth/error'
}
