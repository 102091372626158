export const colors = {
    primary: '#222831',
    secondary: '#EEEEEE',
    textSecondary: '#FFD369',
    textPrimary: '#393E46',
    danger: '#ff603d',
    success: '#ffd369',
    offWhite: '#f5f5f5',
    white: '#fff'
};
