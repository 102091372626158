import HNButton from '@/components/HNButton';
import { styled } from 'styled-components';
import { Image } from '@nextui-org/react';
import { useSession } from 'next-auth/react';
import { Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react';
import HNText from './HNText';
import { colors } from '@/styles/colors';

const PROFILE_WIDTH_HEIGHT = 32;

const ProfileDropdown = ({ onSignOut }: { onSignOut: () => void }) => {
    const { data } = useSession();

    return (
        <Popover backdrop="blur" offset={30} placement="bottom">
            <PopoverTrigger>
                <Profile src={data?.user?.image || '/member.png'} alt="Member" width={PROFILE_WIDTH_HEIGHT} height={PROFILE_WIDTH_HEIGHT} />
            </PopoverTrigger>
            <StyledPopoverContent>
                <InnerContainer>
                    <Email>{data?.user?.email}</Email>
                    <Profile
                        src={data?.user?.image || '/member.png'}
                        alt="Member"
                        width={PROFILE_WIDTH_HEIGHT}
                        height={PROFILE_WIDTH_HEIGHT}
                    />
                    <Line />
                    <HNButton style="dropdown" onClick={onSignOut}>
                        יציאה
                    </HNButton>
                </InnerContainer>
            </StyledPopoverContent>
        </Popover>
    );
};

const StyledPopoverContent = styled(PopoverContent)`
    background-color: ${colors.primary};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    gap: 15px;
`;

const Profile = styled(Image)`
    background-color: rgba(255, 255, 255, 0.87);
    border-radius: 50%;
    border: 2px solid #f3ffef;
    cursor: pointer;

    &:hover {
        background-color: ${colors.textSecondary};
        border: 2px solid ${colors.textSecondary};
    }
`;

const Email = styled(HNText)`
    color: white;
    letter-spacing: 1px;
    font-weight: 400;
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: lightgrey;
`;

export default ProfileDropdown;
